import React, { Component } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
// import CircularProgress from '@mui/material/CircularProgress';
// import Fab from '@mui/material/Fab';

import campaign_data from '../scripts/campaign_data.js';
import { MyContext } from '../MyContext';

import ScoreBox from '../ScoreBox';
// import WeeklyScores from '../WeeklyScores';
// import Campaigns from './Campaigns'

import { Link } from 'react-router-dom';
// import { withSnackbar } from 'notistack';


class Dashboard extends Component {
    static contextType = MyContext;

    constructor(props) {
        super(props)

        this.campdata = campaign_data();

    }

    render() {

        // console.log("Dashboard render");

        this.level = JSON.parse(this.props.level);

        let n_camp = 0;
        if (this.props.campaigns) n_camp = this.props.campaigns.length;

        // console.log("CAMPS", this.props.campaigns);

        let campaignCards = [];

        for (let camp of this.props.campaigns) {

            if(!camp in this.campdata) {
                console.log("Camp not found", camp);
            }

            let wstat;
            let allstat;
            if (camp in this.props.weeklyStats) {
                wstat = this.props.weeklyStats[camp].wstat;
                allstat = this.props.weeklyStats[camp].allstat;
            } else {
                continue;
            }


            let title = this.campdata[camp].label;
            let level = this.level[camp].level;
            let levelInfo = this.level[camp].levelInfo;

            campaignCards.push(<Grid key={"grid"+camp} item xs={12}>
                <Card key={"card"+camp} sx={{ minWidth: 275 }} elevation={5} >
                    <CardContent key={"level"+camp}>
                        <Typography variant="h5" color="text.secondary" gutterBottom>
                            {title}
                        </Typography>

                        <Typography color="text.secondary">
                            You are at level {level}
                        </Typography>
                        <Typography variant="body2">
                            {levelInfo}
                        </Typography>
                    </CardContent>

                    <CardContent key={"stats"+camp}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                            <Stack justifyContent="center" spacing={2} direction="column">
                                <Typography variant="body1" color="text.secondary">
                                    This week
                                </Typography>
                                <ScoreBox data={wstat}></ScoreBox>
                            </Stack>
                            <Stack justifyContent="center" spacing={2} direction="column">
                                <Typography variant="body1" color="text.secondary">
                                    All time
                                </Typography>
                                <ScoreBox data={allstat}></ScoreBox>
                            </Stack>
                        </Box>
                    </CardContent>

                    <CardActions key={"button"+camp}>
                        <Button size="medium" component={Link} to="/sessions" >Inspect individual sessions...</Button>
                    </CardActions>
                </Card>
            </Grid>
            );

        }
        
        let newfeaturestext = false;
        if (this.props.newfeatures) {
            if (this.props.newfeatures > 0) {
                newfeaturestext = "Great! You acquired new objects for your scenery. Check them out!";

            }
        }

        let showAwards = true;

        return (
            <Box margin={2} sx={{ m: "auto", maxWidth: 1000 }}>
                <Grid container spacing={1}>
                    <Grid key="1" item xs={12} md={6}>
                        <Card sx={{ minWidth: 275, minHeight: 150 }} elevation={5}>
                            <CardContent>
                                <Typography variant="h5" color="text.secondary" gutterBottom>
                                    Hello {this.props.user}!
                                </Typography>

                                <Typography variant="h5body" color="text.secondary" gutterBottom>
                                    Your emotional muscle score is {Math.floor(this.context.difficulty)}
                                </Typography>

                                {newfeaturestext && (showAwards) &&
                                    <div>
                                        <Typography variant="body" color="red" gutterBottom>
                                            {newfeaturestext}
                                        </Typography>
                                    </div>
                                }

                            </CardContent>
                            {(showAwards) &&
                                <CardActions>
                                    <Button size="medium" component={Link} to="/scenery" >Go to my scenery...</Button>
                                </CardActions>
                            }
                        </Card>
                    </Grid>

                    <Grid key="2" item xs={12} md={6}>
                        <Card sx={{ minWidth: 275, minHeight: 150 }} elevation={5} >
                            <CardContent>
                                <Typography variant="h5" color="text.secondary" gutterBottom>
                                    Exercises
                                </Typography>
                                <Typography color="text.secondary">
                                    You have {n_camp} campaigns to choose from
                                </Typography>
                                <Typography variant="body2">

                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="medium" component={Link} to="/campaigns" >Go to exercises...</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    {campaignCards}

                    {(showAwards) &&

                        <Grid key="awards" item xs={12}>

                            <Card sx={{ minWidth: 275 }} elevation={5}>
                                <CardContent>
                                    <Typography variant="h5" color="text.secondary" gutterBottom>
                                        Challenges & awards
                                    </Typography>
                                </CardContent>

                                {this.props.mySceneryImage &&
                                    <div>

                                        <Typography color="text.secondary">
                                            Your scenery:
                                        </Typography>
                                        <Typography variant="body2">

                                        </Typography>

                                        <img style={{ width: "50%" }} src={this.props.mySceneryImage} />

                                        <CardActions>
                                            <Button size="medium" component={Link} to="/scenery" >Go to my scenery...</Button>
                                        </CardActions>
                                    </div>
                                }

                            </Card>

                        </Grid>
                    }

                </Grid >
            </Box >
        )
    }
}

export default Dashboard;