
// import { calcLevelCTW } from './calc_level_ctw';
// import { calcLevelCTWkids } from './calc_level_ctw_kids';
// import { calcLevelZen } from './calc_level_zen';
// import { calcLevelAudioSessions } from './calc_level_audio';
// import { calcLevelChill } from './calc_level_chill';
// import { calcLevelRezilio } from './calc_level_rezilio';
// import { calcLevelRezilia } from './calc_level_rezilia';

import { zen_data } from './zen_data.js';
import { chill_data } from './chill_data.js';
import { ctw_data } from './ctw_data.js';
import { kids_ctw_data } from './kids_ctw_data.js';
import { audiomodule_data } from './audiomodule_data.js';
import { rezilio_data } from './rezilio_data.js';
import { rezilia_data } from './rezilia_data.js';
import { cbt_stress_data } from './cbt_stress_data.js';
import { cbt_stress_grp_data } from './cbt_stress_grp_data.js';

import { experimental_data } from './experimental_data.js';
import { imagine_data } from './imagine_data.js';


export default function campaign_data() {

    // var all_data = Object.assign({}, ctw_data, audiomodule_data, kids_ctw_data, zen_data, chill_data, rezilio_data, rezilia_data);
    var all_data = Object.assign({}, imagine_data, cbt_stress_data, cbt_stress_grp_data, ctw_data, zen_data, kids_ctw_data, chill_data, rezilio_data, rezilia_data, experimental_data);

    // console.log("ctw_data", ctw_data);

    // console.log("Campaign data", all_data);

    return all_data;

}

export function findCampHack(exname) {
    // console.log("findCampHack:", exname);

    let campdata = campaign_data();
    for(let campname in campdata) {
        let camp = campdata[campname];

        if(exname in camp.exercises) {
            // console.log("FOUND EX", campname, exname);
            return campname;
        }
    }

    console.log("findCampHack NOT FOUND", exname);
    return false;
}

// export default function campaign_data() {
//     return {
//         "stress_control" : {
//             label: "Calm The Waves",
//             levelType: "CTW",
//             levelFn: calcLevelCTW,
//             exercises: [
//                 "ctw_basic",
//                 "ctw_0",
//                 "ctw_sounds",
//                 "ctw_1",
//                 "ctw_calm",
//                 "ctw_shepard",
//                 "ctw_tetris"
//             ],

//         },

//         "kids_ctw" : {
//             label: "Calm The Waves Kids",
//             levelType: "CTWKIDS",
//             levelFn: calcLevelCTWkids,
//             exercises: [
//                 "kids_ctw_basic",
//                 "kids_ctw_flower",
//                 "kids_ctw_updown",
//                 "kids_ctw_tree",
//                 "kids_ctw_sounds",
//                 "kids_ctw_tetris"
//             ],

//         },

//         "zen": {
//             label: "Zen",
//             levelType: "ZEN",
//             levelFn: calcLevelZen,
//             exercises: [
//                 "zen_csigaver",
//                 "zen_kukucska",
//                 "zen_kapaszkodos",
//                 "zen_lelegzos",
//                 "zen_ujj",
//                 "zen_dudolos",
//                 "zen_hintazos",
//                 "zen_belsobeke",
//                 "zen_updown",
//                 "zen_flower",
//                 "zen_tree",
//                 "zen_tetris"

//             ],

//         },

//         "chill": {
//             label: "Chill",
//             levelType: "CHILL",
//             levelFn: calcLevelChill,
//             exercises: [
//                 "chill_kerdes1",
//                 "chill_relax",
//                 "chill_hangok",                
//                 "chill_csigaver",
//                 "chill_kapaszkodos",
//                 "chill_kisvirag",
//                 "chill_geri",
//                 "chill_csendesito",
//                 "chill_lefel",
//                 "chill_szelidito",
//                 "chill_tree",
//                 "chill_kerdes2",
//                 "chill_tetris",

//             ],

//         },

//         "rezilio": {
//             label: "Rezilio",
//             levelType: "REZILIO",
//             levelFn: calcLevelRezilio,
//             exercises: [
//                 "rezilio_erzesek1",
//                 "rezilio_darts_gyakorlas",
//                 "rezilio_darts_verseny",
//                 "rezilio_relax",
//                 "rezilio_erzesek2a",
//                 "rezilio_erzesek2b",
//                 "rezilio_erzesek2c",
//                 "rezilio_ebertudat",
//                 "rezilio_erzesek3a",
//                 "rezilio_erzesek3b",
//                 "rezilio_erzesek3c",
//                 // "rezilio_erzesek3d",
//                 // "rezilio_jegesviz"
//                 "rezilio_darts_visszavago"

//             ],

//         },

//         "rezilia": {
//             label: "Rezilia",
//             levelType: "REZILIA",
//             levelFn: calcLevelRezilia,
//             exercises: [
//                 "rezilia_erzesek1",
//                 "rezilia_darts_gyakorlas",
//                 "rezilia_darts_verseny",
//                 "rezilia_csigaver",
//                 "rezilia_lelegzos",
//                 "rezilia_kisvirag",
//                 "rezilia_erzesek2a",
//                 "rezilia_erzesek2b",
//                 "rezilia_erzesek2c",
//                 // "rezilia_fajdalomreflexiok",
//                 "rezilia_lefel",
//                 "rezilia_facska",
//                 "rezilia_erzesek3a",
//                 "rezilia_erzesek3b",
//                 "rezilia_erzesek3c",
//                 // "rezilia_erzesek3d",
//                 // "rezilia_jegesviz"
//                 "rezilia_darts_visszavago"
//             ],

//         },

//         "stress_audio" : {
//             label: "Stress management",
//             levelType: "CTW",
//             levelFn: calcLevelAudioSessions,
//             exercises: [
//                 "module_1",
//                 "module_2",
//                 "module_3",
//                 "module_4",
//                 "module_5",

//             ],

//         },


//         "spider" : {
//             label: "Phobias",
//             levelType: "CTW",
//             levelFn: calcLevelCTW,
//             exercises: [
//                 "periodic_audio",
//                 "periodic_cartoon_image",
//                 "periodic_scary_image",
//                 "phobia_keywords",
//                 "phobia_ctw_keywords",
//                 "shrink"
//             ],

//         },

//         "experimental" : {
//             label: "Experimental",
//             levelType: "CTW",
//             levelFn: calcLevelCTW,
//             exercises: [
//                 "proba1",
//                 "proba2",
//                 "proba3",
//                 "proba4",
//                 "proba5",
//                 "ctw_reinforce",
//                 "ctw_subliminal",
//                 "ctw_updown",
//                 "ctw_control",
//                 "keywords",
//                 "ctw_keywords",
//                 "ctw_keywords_nowave",
//                 "ctw_tetris",
//                 "ctw_tetris_nowave",
//                 "raweda",
//                 "exp_belsobeke",
//                 "exp_climate_n",
//                 "exp_climate_p",
//                 "exp_climate_k",
//                 "exp_andrei_1",
//                 "exp_andrei_2",
//                 "exp_andrei_3",
//                 // "exp_tetris",

//             ],

//         },

//     };
// }

