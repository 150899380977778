//         "chill": {
//             label: "Chill",
//             levelType: "CHILL",
//             levelFn: calcLevelChill,
//             exercises: [
//                 "chill_kerdes1",
//                 "chill_relax",
//                 "chill_hangok",                
//                 "chill_csigaver",
//                 "chill_kapaszkodos",
//                 "chill_kisvirag",
//                 "chill_geri",
//                 "chill_csendesito",
//                 "chill_lefel",
//                 "chill_szelidito",
//                 "chill_tree",
//                 "chill_kerdes2",
//                 "chill_tetris",

import { calcLevelChill } from './calc_level_chill';


export const chill_data = {

    chill: {
        label: "Chill",
        levelType: "CHILL",
        levelFn: calcLevelChill,
        exercises: {
            chill_kerdes1: {
                label: "Érzések",
                levelType: "CHILL",
                level: 1,
                eda: false,
                type: "FeedbackSlider",
                params: {
                    text: "Hogy érzed magad most?",
                    textLeft: "Teljesen ellazult vagyok",
                    textRight: "Kifejezetten feszült vagyok",

                },

            },

            chill_relax: {
                label: "Relax",
                level: 2,
                eda: true,
                type: "WaveBasic",
                params: {
                    duration: 240000,
                    backgroundImage: "/media/00szinkombinacioJO_5.jpg",
                    backgroundSound: "/media/01RelaxAudio.m4a",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },


            chill_hangok: {
                label: "Hangok",
                level: 3,
                eda: true,
                adjustVolume: true,
                type: "WaveBasic",
                params: {
                    duration: 220000,
                    backgroundImage: "/media/00szinkombinacioJO_5.jpg",
                    backgroundSound: "/media/02hangok.mp3",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },


            chill_csigaver: {
                label: "Csigavér",
                level: 4,
                eda: true,
                type: "WaveBasic",
                params: {
                    duration: 180000,
                    backgroundImage: "/media/00szinkombinacioJO_5.jpg",

                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },

            chill_kapaszkodos: {
                label: "Kapaszkodós",
                level: 5,
                eda: true,
                type: "WaveBasic",
                params: {
                    duration: 180000,
                    backgroundImage: "/media/megkapaszkodos.png",
                    introLabel: "Keress egy pontot a képen és csak oda nézz!",
                    introTime: 20000,

                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },

            chill_kisvirag: {
                label: "Kisvirág",
                level: 6,
                eda: true,
                type: "Waves2Phase",
                params: {
                    flower: true,
                    backgroundImage: "/media/tree.jpg",
                    duration: 180000,
                    stopWhenGrown: true,
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },
            },


            chill_geri: {
                label: "Geri's game",
                level: 7,
                eda: true,
                type: "VideoEda",
                params: {
                    url: "https://www.youtube.com/watch?v=9IYRC7g2ICg"

                },

            },



            chill_csendesito: {
                label: "Csendesítő",
                level: 8,
                eda: true,
                type: "WaveBasic",
                params: {
                    duration: 180000,
                    backgroundImage: "/media/00szinkombinacioJO_5.jpg",
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },

            chill_lefel: {
                label: "Fel-Le",
                level: 9,
                eda: true,
                type: "WaveUpdown",
                params: {
                    backgroundImage: "/media/moon.jpg",
                    visual: {
                        incr: ["/media/incr/fire1.jpg", "/media/incr/fire2.jpg", "/media/incr/fire3.jpg", "/media/incr/fire4.jpg", "/media/incr/fire5.jpg", "/media/incr/fire6.jpg",],
                        decr: ["/media/decr/water1.jpg", "/media/decr/water2.jpg", "/media/decr/water3.jpg", "/media/decr/water4.jpg", "/media/decr/water5.jpg", "/media/decr/water6.jpg", "/media/decr/water7.jpg", "/media/decr/water8.jpg",],
                    },
                    duration: 180000,
                    point_mult: 1,
                    l_incr: 2,
                    l_decr: 1,
                    r_incr: 2,
                    r_decr: 1,

                },
            },

            chill_szelidito: {
                label: "Szelídítő",
                level: 10,
                eda: true,
                type: "WaveBasic",
                params: {
                    duration: 180000,
                    backgroundImage: "/media/00szinkombinacioJO_5.jpg",
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1
                },

            },

            chill_tree: {
                label: "Fácska",
                level: 11,
                eda: true,
                type: "Waves2Phase",
                params: {
                    tree: true,
                    backgroundImage: "/media/desert4.jpg",
                    duration: 180000,
                    stopWhenGrown: true,
                    growSpeed: 0.5,
                    point_mult: 1,
                    l_incr: 2,
                    l_decr: 1,
                    r_incr: 2,
                    r_decr: 1
                },
            },





            chill_kerdes2: {
                label: "Érzések",
                levelType: "CHILL",
                level: 12,
                eda: false,
                type: "FeedbackSlider",
                params: {
                    text: "Hogy érzed magad most?",
                    textLeft: "Teljesen ellazult vagyok",
                    textRight: "Kifejezetten feszült vagyok",

                },

            },


            chill_tetris: {
                label: "Tetris",
                levelType: "CHILL",
                level: 13,
                eda: true,

                type: "Tetris",
                params: {
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1,

                },

            },

        }
    }

}