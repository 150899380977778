import { param } from "jquery";

const nWaveLimit = 10;

export function calcDifficultyFromParams(params) {
    let d = 0;

    // Calculate d based on params
    // ...

    let max = 2;

    d = params.l_incr - 1;
    d /= max;

    d *= 99;
    d += 1;

    let nWave = 1;
    if(d>nWaveLimit) {
        nWave = 2;
    }


    return [nWave, d];
}


// difficulty goes from 1-100
export function calcParamsFromDifficulty(d) {

    let params = {}

    // Calculate params based on d 
    params.nWave = 1;
    if(d>nWaveLimit) {
        params.nWave = 2;
    } else {
        params.nWave = 1;
    }

    let a = (d-1)/99; // 0-1

    let max = 2;

    a = max * a;

    let incr = 1 + a;
    let decr = 1 / incr ;

    params.l_incr = incr;
    params.l_decr = decr;

    params.r_incr = incr;
    params.r_decr = decr;

    return params;
    
    // // 1
    // l_incr: 1,
    // l_decr: 1,
    // r_incr: 1,
    // r_decr: 1

    // // 2
    // l_incr: 2,
    // l_decr: 1,
    // r_incr: 2,
    // r_decr: 1

    // //3 
    // l_incr: 2,
    // l_decr: 0.5,
    // r_incr: 2,
    // r_decr: 0.5


}
