import React from 'react'
import Wave from 'react-wavify'
// import { Howl } from 'howler'
import { CalcScore } from '../scripts/calc_score';
import { CalcSessionStats } from '../scripts/calc_sessionstats';

// import Backdrop from '@mui/material/Backdrop';
import Fab from '@mui/material/Fab';
// import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import NavigationIcon from '@mui/icons-material/Navigation';
import WaveResultCard from '../WaveResultCard';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography'
import { CircularProgress } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { MyContext } from '../MyContext';
import { Grid } from '@mui/material';

class WaveUpdown extends React.Component {
    static contextType = MyContext;

    constructor(props) {
        super(props);

        this.treeRef = React.createRef();

        this.col1 = [178, 137, 239, 0.7];

        this.color1 = this.rgba(this.col1);

        this.params = props.params;

        //if (process.env.NODE_ENV === 'development') this.test = true;
        this.simrelax = 100;
        //this.test = true;

        this.decr_ended_ts = false;

        this.calc_score = new CalcScore(this.params);
        this.calc_stats = new CalcSessionStats(); // TODO +_++++++++++++++++++++++++++++++++++++++++++

        this.eda = [];

        this.scores = [];
        this.events = [];

        this.direction = "up";

        this.n = 0;
        this.stage = 0;

        this.lasts = 100;

        this.t = 10;
        this.start = Date.now();
        this.end = 0;

        this.maxs = 0;
        this.maxTs = Date.now();
        this.downStart = Date.now();


        this.state = {
            showResult: false,
            amplitude: 0,
            askFeedback: false,
            textAreaValue: ""
        }

        if (this.params.duration) {
            const dur = this.params.duration;

            this.timeout = setTimeout(() => {
                this.ended();
            }, dur);
        }

        // if (this.params.backgroundImage) {
        //     this.bgImg = this.params.backgroundImage;
        // }

        this.setImage("incr");
    }

    finished() {
        // on finish, we do not send data, because we already called store_cb in "ended"
        this.props.finished_cb({}, false);
    }


    async ended() {
        if (this.endedCalled) return;
        this.endedCalled = true;

        clearTimeout(this.timeout);

        console.log("Ended");

        // let sessionData = {
        //     eda: this.eda,
        //     scores: this.scores,
        // };

        // let sessionId = await this.props.store_cb(sessionData);

        //console.log("**************************** Session ID:", sessionId);

        //Now read back
        // await fetch

        this.setState({ askFeedback: true, ended: true });
        //this.setState({ showResult: true, sessionData: sessionData, ended: true });

    }

    async save() {
        this.setState({ saving: true });

        let sessionData = {
            eda: this.eda,
            scores: this.scores,
            events: this.events,
            feedback: this.state.textAreaValue,
        };

        let sessionId = await this.props.store_cb(sessionData);

        console.log("**************************** Session ID:", sessionId);

        //Now read back
        // await fetch

        this.setState({ showResult: true, askFeedback: false, sessionData: sessionData, saving: false });

    }

    setImage(dir) {
        if (!this.props.params.visual) return;

        let images = this.props.params.visual[dir];
        let len = images.length;

        this.bgImg = images[Math.floor(Math.random() * len)];
    }


    onEda(gsr, acc) {
        if (this.state.ended) {
            return;
        }

        let ts = Date.now();

        if (!this.lastts) {
            this.lastts = ts;
            return;
        }

        this.eda.push([ts, gsr])

        let sl, sr;

        [sl, sr] = this.calc_score.calc_one(ts, gsr);

        this.calc_stats.calc_one(ts, sl, sr);

        let s = Math.max(sl, sr); // this is max! Harder than min...

        if (this.test) {
            s = this.simrelax;

            //console.log("simrelax", this.simrelax);
        }


        this.scores.push([ts, sl, sr]);

        // let relax = 0;
        // if (this.calc_stats.relaxpts) relax = this.calc_stats.relaxpts;

        // let amp = (50 - relax) * 2;
        // if (amp <= 0) {
        //     this.ended();
        // }


        let now = Date.now();

        if (s > this.maxs) {
            this.maxs = s;
            this.maxTs = now;

            //this.maxs = 0;
        }
        if (s < this.mins) {
            this.mins = s;
            this.minTs = now;

            //this.mins = 1000;
        }

        if (s > 150) s = 150;

        if (s <= 0) s = 0;

        if (this.direction === "down" && s <= 50) {
            this.direction = "up";

            this.setImage("incr");
            this.events.push([ts, "up"])

            this.maxs = 0;
            this.maxTs = now;

        }

        if (this.direction === "up" && s >= 120 && now - this.maxTs > 3000) {
            this.direction = "down";

            this.downStart = now;

            this.setImage("decr");
            this.events.push([ts, "down"])

        }

        if (s < 10 && (!this.params.uptext)) {

            this.msg = "";

            if (now % 1000 >= 500) {
                let ll = Math.floor(s);

                this.msg = <div>
                    < div style={{ fontSize: '10vw', color: "red" }}>
                        Ne engedd 0-ra!
                    </div >

                    < div style={{ fontSize: '20vw', color: "red" }}>
                        {ll}
                    </div >

                </div>

            }


        } else {

            if (this.direction === "down") {

                let a = 1;
                if (now - this.downStart > 2000) {
                    a = Math.max(1 - (now - this.downStart - 2000) / 3000, 0);

                }

                let c = this.rgba([0, 0, 0, a]);

                console.log("A", a, c);

                this.msg = < div style={{ fontSize: '20vw', color: c }}>
                    Nyugi...
                </div >

                if(this.params.downtext) this.msg = this.params.downtext



            }

            if (this.direction === "up") {
                this.msg = <div style={{ fontSize: '10vw', color: "black" }}>
                    Emeld!
                    < div style={{ fontSize: '20vw', color: "red" }}>
                        {Math.floor(s)}
                    </div >

                </div>

                if(this.params.uptext) this.msg = this.params.uptext



            }

        }
        // no update for more than dt 




        //     console.log("NO UPDATE FOR DT");


        //     if(this.maxTs > this.minTs) {
        //         // decreasing

        //         this.events.push([ts, "top inflection"]);

        //         this.setState({ backdropLabel: "Nagyon jol kontrollalod, hagyd hogy essen lejjebb ameddig csak tud!" });
        //         setTimeout(()=>{this.setState({ backdropLabel: false })}, 6000);

        //         this.setImage("decr");

        //         //if(this.stage>3) this.ended();


        //     } else {

        //         this.events.push([ts, "bottom inflection"]);

        //         this.setImage("incr");

        //         if(s>10) {
        //             this.setState({ backdropLabel: "Most engedd el, hadd menjen feljebb ha akar!" });

        //         } else {
        //             if(this.stage == 0) this.setState({ backdropLabel: "Teljesen levitted! Most gondolj egy nagyon vidam, aktiv emlekre!" });
        //             if(this.stage == 1) this.setState({ backdropLabel: "Most gondolj egy cselekedetedre amire buszke vagy!" });
        //             if(this.stage == 2) this.setState({ backdropLabel: "Szamold ki fejben: 12*72=?" });
        //             if(this.stage == 3) this.ended();
        //             this.stage +=1 ;
        //         }

        //         setTimeout(()=>{this.setState({ backdropLabel: false })}, 6000);

        //     }


        //     this.mins = 1000;
        //     this.maxs = 0;
        //     this.minTs = this.maxTs = now;

        // }

        this.setState({ amplitude: s });

        console.log("amp", s);

        this.lasts = s;


        this.lastts = ts;

    }


    rgba(c) {
        return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`
    }

    siminc() {
        this.simrelax += 5;
    }

    simdec() {
        this.simrelax -= 5;
        if (this.simrelax < 0) this.simrelax = 0;
    }

    handleTextChange(event) {
        this.setState({ textAreaValue: event.target.value });

    }

    async feedback() {
        await this.save();
        this.setState({ askFeedback: false });
    }

    render() {

        if (this.state.saving) {
            return (
                <div>
                    <p>Saving...</p>
                    <CircularProgress />
                </div>
            );
        }

        if (this.state.askFeedback) {
            return (
                <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

                    <br></br>

                    <Typography>
                        If you wish, you can enter your thoughts here:
                    </Typography>

                    <br></br>

                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={5}

                        //placeholder="If you wish, you can enter your thoughts here..."
                        value={this.state.textAreaValue}
                        onChange={this.handleTextChange.bind(this)}
                        style={{ width: '90%' }}
                    />

                    <br></br>

                    <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.feedback.bind(this)}>Continue</Button>

                </Box>
            );
        }

        if (this.state.showResult) {

            //let final_score = this.calc_score.calc_final_score(this.scores);


            //let total = Math.floor(this.state.final_score.total);

            //let rank = this.calcRank(this.state.hist, total);

            //console.log("Show RESULT", this.state.sessionData);

            let ses = {
                type: 'WaveReinforce',
                exercise: this.props.exercise,
                campaign: this.props.campaign,
                data: this.state.sessionData,
                ts: Date.now(),
                device: this.context.deviceName,
                user: this.context.user
            }


            return (
                <div>
                    <p>
                        Congratulations! You gained experience in controlling your thoughts and emotions,
                        and collected some relax points as well!
                    </p>

                    <Box maxWidth='90%' margin={3}>

                        <WaveResultCard session={ses}></WaveResultCard>

                        <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.finished.bind(this)}>SAVE SESSION</Button>

                    </Box>
                </div>
            );
        }



        const style = {
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',
            position: 'fixed',
        };

        let bgImg = this.bgImg;

        return (


            <div>

                < Fab variant="contained" color="primary" >
                    <CheckCircleIcon onClick={this.ended.bind(this)} />
                </Fab >


                <div style={{ position: 'absolute', left: '0px', top: 0, zIndex: '-10', height: "100%", width: "100%" }}>
                    <img
                        src={bgImg}
                        style={{ height: "100%", width: "100%", objectFit: "cover" }}
                    />
                </div>


                {/* < div style={{ fontSize: '20vw', position: 'absolute', left: '0px', top: '40%', zIndex: '-1', height: "100%", width: "100%" }}>

                    {this.msg}
                </div > */}

                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh', zIndex: '-1', }}
                >

                    <Grid item xs={3}>
                        {this.msg}
                    </Grid>

                </Grid>

                < Wave fill={this.color1}
                    paused={false}
                    options={{
                        height: 50,
                        amplitude: this.state.amplitude,
                        speed: 0.15,
                        points: 4
                    }}
                    style={{ position: 'absolute', left: '0px', top: '50%', zIndex: '-3', height: "50%" }}
                ></Wave >

                {
                    this.test &&
                    <Fab variant="contained" color="primary" >
                        <NavigationIcon onClick={this.siminc.bind(this)} />
                    </Fab>
                }
                {
                    this.test &&
                    <Fab variant="contained" color="primary" >
                        <NavigationIcon onClick={this.simdec.bind(this)} />
                    </Fab>
                }

            </div >


        );

    };
}

export default WaveUpdown;